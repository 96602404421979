import React, {Component} from 'react';
import { Routes, Route } from "react-router-dom";
import classes from './App.module.scss';
// import logoText from './assets/images/inizio.png';
import Home1 from "./hoc/Home1/Home1";
// import Home1b from "./hoc/Home1/Home1b";
// import Home1c from "./hoc/Home1/Home1c";
// import Home1d from "./hoc/Home1/Home1d";
// import Home2 from "./hoc/Home2/Home2";

class App extends Component {
  render() {
    let routes = (
      <Routes>
        <Route exact={true} path="/" element={<Home1 />} />
        {/* <Route path="/home1b" element={<Home1b />} />
        <Route path="/home1c" element={<Home1c />} />
        <Route path="/home1d" element={<Home1d />} />
        <Route path="/home2" element={<Home2 />} /> */}
      </Routes>
    );

    return (
      <div className={classes.inzWrap}>
        <main className={classes.MainContent}>   
          {routes}
        </main>        
      </div>
    );
  }
}
export default App;