import React, {Component} from 'react';
// import { Link } from "react-router-dom";
import classes from '../../App.module.scss';
import video from '../../assets/images/combo.mov';
import still from '../../assets/images/exterior1.jpg';

class Home1 extends Component {
  render() {
    return (
      <div className={classes.Home1}>
        <div className={classes.inzVideo}>
          <div className={classes.ivWrap}>
            <div className={classes.vidOverlay}>
              <div className={classes.vidText}>
                <h2>Inizio<br/><span>Orange Beach, AL</span></h2>
                {/* <img src={logoText} className={classes.logo} alt="ph alt" /> */}
                <h1>Luxury Yacht Charters on the<br/> Gulf Coast of Alabama</h1>
                <h3>Coming Soon</h3>
              </div>
            </div>

            <video
              src={video}
              frameBorder="0"
              title="Inizio Charters in Orange Beach, Alabama"
              allow="accelerometer;autoplay;encrypted-media;gyroscope;picture-in-picture"
              allowFullScreen
              data-uk-responsive
              uk-video="automute:true;autoplay:inview;"
              className={classes.fullVideo}
              loop={true}
            />

            <div className={classes.vidStill}><img src={still} className={classes.vidImage} alt="ph alt" /></div>
          </div>
        </div>    
      </div>
    );
  }
}
export default Home1;